div:has(> div.notification-app) {
    height: 100vh;
}

.notification-app {
    .debug-menu {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .notification-app {
        width: 400px;
    }

    .notification-card {
        border: black 1px solid; 
        padding: 5px; 
        margin-bottom: 5px;
        background-color: #f9f9f9;
    }
}